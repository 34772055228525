import HomePage from './HomePage'



function App() {
  

  return (
   <HomePage />
  )
}

export default App
